<template>
  <step-lesson-tabs :tabs="tabs" class="module1-part2-lesson"></step-lesson-tabs>
</template>

<script>
import StepLessonTabs from '@/components/training/StepLessonTabs'

export default {
  name: 'Module1Part2Lesson',
  components: { StepLessonTabs },
  data () {
    return {
      tabs: [
        {
          name: 'tab1',
          label: this.$t('module1.part2.tabs.lesson1'),
          component: () => import('@/components/training/module1/part2/Module1Part2Lesson1')
        },
        {
          name: 'tab2',
          label: this.$t('module1.part2.tabs.lesson2'),
          component: () => import('@/components/training/module1/part2/Module1Part2Lesson2')
        },
        {
          name: 'tab3',
          label: this.$t('module1.part2.tabs.lesson3'),
          component: () => import('@/components/training/module1/part2/Module1Part2Lesson3')
        },
        {
          name: 'tab4',
          label: this.$t('module1.part2.tabs.lesson4'),
          component: () => import('@/components/training/module1/part2/Module1Part2Lesson4')
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.module1-part2-lesson {
  .lesson {
    max-width: $training-max-width;
    margin: 0 auto;
    padding: $space-sm $space-s $space-xl $space-s;
    display: flex;
    flex-direction: column;
    gap: $space-xl;
    > button.app-button-layout {
      width: 100%;
      max-width: 360px;
      margin: 0 auto;
    }
    ul {
      padding: $space-m;
    }
  }
  .flex-icon-block {
    margin-top: $space-s;
    display: flex;
    align-items: center;
    &.align-start {
      align-items: flex-start;
    }
    img {
      margin-right: $space-m;
    }
    p {
      text-align: left;
    }
  }
  .open-modal-button {
    display: flex;
    justify-content: center;
    margin: $space-m auto;
  }
  .big-text {
    color: $c-secondary;
    font-weight: $fw-l;
  }
  .annotation {
    color: $c-secondary;
    font-size: .813rem;
    font-weight: 300;
  }
  .checklist {
    .item + .item {
      margin-top: $space-m;
    }
    .item {
      display: flex;
      align-items: flex-start;
      text-align: left;
      padding-right: $space-m;
      &:before {
        content: '';
        flex-shrink: 0;
        background-image: url('../../assets/icons/check-circled.svg');
        background-repeat: no-repeat;
        background-size: 14px;
        width: 15px;
        margin-right: $space-m;
        height: 20px;
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .module1-part2-lesson {
    .lesson {
      .list-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        gap: $space-s;
        .item {
          margin: 0 !important;
        }
      }
    }
  }
}
</style>
